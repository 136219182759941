import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`27th January 2022`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "https://dotty.epfl.ch/"
          }}>{`Scala 3`}</a>{` for `}<inlineCode parentName="p">{`armeria-scala`}</inlineCode>{` and `}<inlineCode parentName="p">{`armeria-scalapb`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3614"
          }}>{`#3614`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4036"
          }}>{`#4036`}</a></p>
        <Tip mdxType="Tip">
          <p parentName="li">{`Scala 3 modules are currently experimental. We're looking forward to your feedback.`}</p>
        </Tip>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now fluently convert an `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` into a desired type using `}<a parentName="p" {...{
            "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
          }}>{`type://WebClient`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4021"
          }}>{`#4021`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient client = WebClient.of("https://api.example.com");
CompletableFuture<ResponseEntity<MyObject>> response =
  client.prepare()
        .get("/v1/items/1")
        .asJson(MyObject.class)
        .execute();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://BlockingWebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/BlockingWebClient.html"
          }}>{`type://BlockingWebClient`}</a>{` to wait for an `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` to be completed. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4021"
          }}>{`#4021`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`BlockingWebClient client = BlockingWebClient.of("https://api.example.com");
ResponseEntity<MyObject> response =
  client.prepare()
        .get("/v1/items/1")
        .asJson(MyObject.class)
        .execute();
`}</code></pre>
        <Tip mdxType="Tip">
          <p parentName="li">{`Note that you should never use this client in an `}<inlineCode parentName="p">{`EventLoop`}</inlineCode>{` thread.
Use it from a non-`}<inlineCode parentName="p">{`EventLoop`}</inlineCode>{` thread such as `}<a parentName="p" {...{
              "href": "type://BlockingTaskExecutor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/BlockingTaskExecutor.html"
            }}>{`type://BlockingTaskExecutor`}</a>{`.`}</p>
        </Tip>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now fluently build a gRPC client with various options using
`}<a parentName="p" {...{
            "href": "type://GrpcClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/grpc/GrpcClientBuilder.html"
          }}>{`type://GrpcClientBuilder`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3981"
          }}>{`#3981`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3999"
          }}>{`#3999`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3964"
          }}>{`#3964`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3975"
          }}>{`#3975`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcClients
  .builder(...)
  .decorator(myDecorators)
  .maxResponseMessageLength(MAX_MESSAGE_SIZE)
  .jsonMarshallerFactory(descriptor -> {
    ...
  })
  .intercept(myInterceptors)
  .build(MyStub.class);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now fluently build an `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` by using `}<a parentName="p" {...{
            "href": "type://HttpResponseBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponseBuilder.html"
          }}>{`type://HttpResponseBuilder`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3398"
          }}>{`#3398`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3941"
          }}>{`#3941`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpResponse
  .builder()
  .ok()
  .header(HttpHeaderNames.USER_AGENT, "Armeria")
  .content(content)
  .build(); 
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://StreamMessage#mapAsync(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#mapAsync(java.util.function.Function)"
          }}>{`type://StreamMessage#mapAsync(Function)`}</a>{` to transform a `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{`
using an async operation. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3916"
          }}>{`#3916`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3962"
          }}>{`#3962`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`StreamMessage<Integer> userIds = StreamMessage.of(...);
StreamMessage<UserInfo> userInfos = userIds.mapAsync(this::getUserInfo);

CompletableFuture<UserInfo> getUserInfo(int userId) {
  ...
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://HttpResponse#peekHeaders(Consumer):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#peekHeaders(java.util.function.Consumer)"
          }}>{`type://HttpResponse#peekHeaders(Consumer)`}</a>{`, `}<a parentName="p" {...{
            "href": "type://HttpMessage#peekData(Consumer):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpMessage.html#peekData(java.util.function.Consumer)"
          }}>{`type://HttpMessage#peekData(Consumer)`}</a>{`,
`}<a parentName="p" {...{
            "href": "type://HttpMessage#peekTrailers(Consumer):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpMessage.html#peekTrailers(java.util.function.Consumer)"
          }}>{`type://HttpMessage#peekTrailers(Consumer)`}</a>{` and `}<a parentName="p" {...{
            "href": "type://StreamMessage#peekError(Consumer):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#peekError(java.util.function.Consumer)"
          }}>{`type://StreamMessage#peekError(Consumer)`}</a>{` operators on
`}<a parentName="p" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{` and `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3097"
          }}>{`#3097`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3988"
          }}>{`#3988`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3949"
          }}>{`#3949`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpRequest request =
  HttpRequest.of(RequestHeaders.of(HttpMethod.POST, "/items"),
                 HttpData.ofUtf8("data1,data2"))
                 HttpHeaders.of("trailer", "foo"));
HttpRequest peeked =
  request.peekData(data -> {
    assert data.toStringUtf8().equals("data1,data2");
  }).peekTrailers(trailers -> {
    assert trailers.get("trailer").equals("foo");
  });

HttpRequest failed = 
  HttpRequest.ofFailure(new IllegalStateException("Something went wrong."));
HttpRequest peeked = failed.peekError(cause -> {
  assert cause instanceof IllegalStateException;
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now generate an error response differently in `}<a parentName="p" {...{
            "href": "type://ServerErrorHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerErrorHandler.html"
          }}>{`type://ServerErrorHandler`}</a>{` depending
on the `}<a parentName="p" {...{
            "href": "type://RequestHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeaders.html"
          }}>{`type://RequestHeaders`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4037"
          }}>{`#4037`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<inlineCode parentName="p">{`{*var}`}</inlineCode>{` and `}<inlineCode parentName="p">{`:*var`}</inlineCode>{` path patterns to capture rest paths of a request. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3031"
          }}>{`#3031`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3997"
          }}>{`#3997`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .service("/api/{item}/route/{*contents}", (ctx, req) -> {
    // If a request path is "/api/123/route/foo/bar/baz",
    // '*contents' should be foo/bar/baz
    assert ctx.pathParam("contents").equals("foo/bar/baz");
  });
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://ServiceRequestContext#queryParams(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#queryParams(java.lang.String)"
          }}>{`type://ServiceRequestContext#queryParams(String)`}</a>{` to get the decoded query
parameters. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3955"
          }}>{`#3955`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3960"
          }}>{`#3960`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now send query parameters using `}<a parentName="p" {...{
            "href": "type://QueryParams:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/QueryParams.html"
          }}>{`type://QueryParams`}</a>{` with `}<a parentName="p" {...{
            "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
          }}>{`type://WebClient`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3915"
          }}>{`#3915`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3921"
          }}>{`#3921`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`QueryParams params = QueryParams.of("foo", "bar");
WebClient client = ...;
// Sends 'GET /api/items/1?foo=bar'
client.get("/api/items/1", params);
// Sends 'POST /api/items?foo=bar' with body '...'
client.post("/api/items", params, content);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now write a `}<inlineCode parentName="p">{`StreamMessage<HttpData>`}</inlineCode>{` into a file using
`}<a parentName="p" {...{
            "href": "type://StreamMessages#writeTo(StreamMessage,Path,)"
          }}>{`type://StreamMessages#writeTo(StreamMessage,Path,)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3870"
          }}>{`#3870`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3874"
          }}>{`#3874`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`StreamMessage<HttpData> stream = ...;
Path destination = ...;
StreamMessages.writeTo(stream, destination);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now force a state transition of a `}<a parentName="p" {...{
            "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
          }}>{`type://CircuitBreaker`}</a>{`
by using `}<a parentName="p" {...{
            "href": "type://CircuitBreaker#enterState(CircuitState):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html#enterState(com.linecorp.armeria.client.circuitbreaker.CircuitState)"
          }}>{`type://CircuitBreaker#enterState(CircuitState)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4010"
          }}>{`#4010`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4022"
          }}>{`#4022`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`CircuitBreaker circuitBreaker = CircuitBreaker.of(...);
circuitBreaker.enterState(CircuitState.FORCED_OPEN);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now conveniently create a `}<a parentName="p" {...{
            "href": "type://RetryRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html"
          }}>{`type://RetryRule`}</a>{` and `}<a parentName="p" {...{
            "href": "type://RetryRuleWithContent:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRuleWithContent.html"
          }}>{`type://RetryRuleWithContent`}</a>{` by specifying
a `}<a parentName="p" {...{
            "href": "type://Backoff:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/Backoff.html"
          }}>{`type://Backoff`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3875"
          }}>{`#3875`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2899"
          }}>{`#2899`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3895"
          }}>{`#3895`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`RetryRule.failsafe(Backoff.fixed(1000));
RetryRuleWithContent.onException(Backoff.fixed(2000));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now dynamically change the maximum number of concurrent active requests of
a `}<a parentName="p" {...{
            "href": "type://ConcurrencyLimitingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/limit/ConcurrencyLimitingClient.html"
          }}>{`type://ConcurrencyLimitingClient`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3842"
          }}>{`#3842`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3985"
          }}>{`#3985`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ConcurrencyLimitingClient.newDecorator(new DynamicLimit());

class DynamicLimit implements IntSupplier {
  @Override
  public int getAsInt() {
    // Dynamically returns the current limitation.
    ...
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now split an `}<a parentName="p" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{` into `}<a parentName="p" {...{
            "href": "type://RequestHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeaders.html"
          }}>{`type://RequestHeaders`}</a>{`, `}<inlineCode parentName="p">{`StreamMessage<HttpData>`}</inlineCode>{`
and trailers using `}<a parentName="p" {...{
            "href": "type://HttpRequest#split():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html#split()"
          }}>{`type://HttpRequest#split()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3924"
          }}>{`#3924`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3953"
          }}>{`#3953`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpRequest request = HttpRequest.of(...);
SplitHttpRequest splitRequest = request.split();

RequestHeaders headers = splitRequest.headers();
StreamMessage<HttpData> body = splitRequest.body();
CompletableFuture<HttpHeaders> trailers = splitRequest.trailers();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added more well-known MIME type constants to `}<a parentName="p" {...{
            "href": "type://MediaType:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/MediaType.html"
          }}>{`type://MediaType`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4040"
          }}>{`#4040`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize the `}<a parentName="p" {...{
            "href": "type://MediaType:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/MediaType.html"
          }}>{`type://MediaType`}</a>{` of a file when using `}<a parentName="p" {...{
            "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
          }}>{`type://FileService`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4001"
          }}>{`#4001`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4009"
          }}>{`#4009`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`FileService
  .builder(resource)
  .mediaTypeResolver((path, contentEncoding) -> {
    if (path.endsWith(".proto")) {
      return MediaType.PROTOBUF;
    }
    if (path.endsWith(".csv")) {
      return MediaType.CSV_UTF_8;
    }
    // Pass to the default resolver.
    return null;
  })
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now send requests to a test server by using `}<a parentName="p" {...{
            "href": "type://ServerExtension#webClient():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html#webClient()"
          }}>{`type://ServerExtension#webClient()`}</a>{`
or `}<a parentName="p" {...{
            "href": "type://ServerRule#webClient():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit4/server/ServerRule.html#webClient()"
          }}>{`type://ServerRule#webClient()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3761"
          }}>{`#3761`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3890"
          }}>{`#3890`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{` @RegisterExtension
 static ServerExtension server = new ServerExtension() {
   @Override
   protected void configure(ServerBuilder sb) {
     ...
   }
 };

 @Test
 void test() {
   WebClient client = server.webClient(cb -> {  // 👈👈👈
     cb.decorator(LoggingClient.newDecorator());
   });
   client.get("/foo").aggregate().join();
 }
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now export metrics as `}<a parentName="p" {...{
            "href": "https://github.com/OpenObservability/OpenMetrics/blob/main/specification/OpenMetrics.md"
          }}>{`OpenMetrics`}</a>{`
format using `}<a parentName="p" {...{
            "href": "type://PrometheusExpositionService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/PrometheusExpositionService.html"
          }}>{`type://PrometheusExpositionService`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3926"
          }}>{`#3926`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3928"
          }}>{`#3928`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://UnframedGrpcStatusMappingFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/UnframedGrpcStatusMappingFunction.html"
          }}>{`type://UnframedGrpcStatusMappingFunction`}</a>{` to customize how a gRPC status is mapped to
an `}<a parentName="p" {...{
            "href": "type://HttpStatus:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpStatus.html"
          }}>{`type://HttpStatus`}</a>{` when using an unframed gRPC service. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3683"
          }}>{`#3683`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3948"
          }}>{`#3948`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`UnframedGrpcStatusMappingFunction mappingFunction = 
  (ctx, grpcStatus, cause) -> {
    if (grpcStatus.getCode() == DEADLINE_EXCEEDED) {
      return INTERNAL_SERVER_ERROR;
    }
    // Pass to the default mapping function.
    return null;
  };

UnframedGrpcErrorHandler errorHandler = 
  UnframedGrpcErrorHandler.ofJson(mappingFunction);

GrpcService
  .builder()
  .unframedGrpcErrorHandler(errorHandler);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now expose `}<inlineCode parentName="p">{`WebOperation`}</inlineCode>{`s of Actuator to a port of internal services. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3919"
          }}>{`#3919`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3946"
          }}>{`#3946`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-yaml"
          }}>{`armeria:
  ...
  internal-services:
    # Actuator will be exposed to 18080 port.
    include: actuator, health 
    port: 18080
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<a parentName="p" {...{
            "href": "type://JettyServiceBuilder#tlsReverseDnsLookup(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyServiceBuilder.html#tlsReverseDnsLookup(boolean)"
          }}>{`type://JettyServiceBuilder#tlsReverseDnsLookup(boolean)`}</a>{` that makes `}<a parentName="p" {...{
            "href": "type://JettyService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyService.html"
          }}>{`type://JettyService`}</a>{`
perform a reverse DNS lookup for each TLS connection, which may be useful for
servlets that assume `}<inlineCode parentName="p">{`ServletRequest.getRemoteHost()`}</inlineCode>{` will return a remote host name
rather than a remote IP address.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<a parentName="p" {...{
            "href": "type://JettyServiceBuilder#httpConfiguration(HttpConfiguration):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyServiceBuilder.html#httpConfiguration(org.eclipse.jetty.server.HttpConfiguration)"
          }}>{`type://JettyServiceBuilder#httpConfiguration(HttpConfiguration)`}</a>{` that allows a user to set
Jetty `}<inlineCode parentName="p">{`HttpConfiguration`}</inlineCode>{` in a type-safe manner.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`You can now distinguish services, messages and enums with the same name by their package names from
`}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` UI. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3979"
        }}>{`#3979`}</a></li>
      <li parentName="ul">{`Improved the memory footprint of `}<a parentName="li" {...{
          "href": "type://PrometheusExpositionService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/PrometheusExpositionService.html"
        }}>{`type://PrometheusExpositionService`}</a>{` by removing large byte array
allocations. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3929"
        }}>{`#3929`}</a></li>
      <li parentName="ul">{`Meters created by `}<a parentName="li" {...{
          "href": "type://MoreMeters:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MoreMeters.html"
        }}>{`type://MoreMeters`}</a>{` are now using more accurate percentiles. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4047"
        }}>{`#4047`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`ClassCastException`}</inlineCode>{` when duplicating an `}<a parentName="li" {...{
          "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
        }}>{`type://HttpRequest`}</a>{` or
an `}<a parentName="li" {...{
          "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
        }}>{`type://HttpResponse`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4030"
        }}>{`#4030`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4032"
        }}>{`#4032`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`IllegalArgumentException`}</inlineCode>{` if an upper-bounded wildcard type with `}<a parentName="li" {...{
          "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
        }}>{`type://@Param`}</a>{`
is used in annotated services. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4007"
        }}>{`#4007`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4008"
        }}>{`#4008`}</a></li>
      <li parentName="ul">{`You can now properly get `}<inlineCode parentName="li">{`SameSite`}</inlineCode>{` attribute from `}<a parentName="li" {...{
          "href": "type://Cookie#sameSite():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Cookie.html#sameSite()"
        }}>{`type://Cookie#sameSite()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3968"
        }}>{`#3968`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3984"
        }}>{`#3984`}</a></li>
      <li parentName="ul">{`You no longer see an `}<a parentName="li" {...{
          "href": "type://EmptyEndpointGroupException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EmptyEndpointGroupException.html"
        }}>{`type://EmptyEndpointGroupException`}</a>{` when an `}<a parentName="li" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{` is successfully
resolved with the initial `}<a parentName="li" {...{
          "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`typeplural://Endpoint`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3978"
        }}>{`#3978`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpRequestBuilder#header(CharSequence,Object):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequestBuilder.html#header(java.lang.CharSequence,java.lang.Object)"
        }}>{`type://HttpRequestBuilder#header(CharSequence,Object)`}</a>{` does not overwrite an old header value
anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3932"
        }}>{`#3932`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3941"
        }}>{`#3941`}</a>
        <ul parentName="li">
          <li parentName="ul">{`A new header value will be appended to the old value.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`413 Request Entity Too Large`}</inlineCode>{` when a server receives a cleartext (h2c) upgrade request
larger than 16384 bytes. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3859"
        }}>{`#3859`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3913"
        }}>{`#3913`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The maximum allowed length of an upgrade request will respect `}<a parentName="li" {...{
              "href": "type://ServerBuilder#maxRequestLength(long):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#maxRequestLength(long)"
            }}>{`type://ServerBuilder#maxRequestLength(long)`}</a>{`
or `}<a parentName="li" {...{
              "href": "type://VirtualHost#maxRequestLength():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHost.html#maxRequestLength()"
            }}>{`type://VirtualHost#maxRequestLength()`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Fixed `}<a parentName="li" {...{
          "href": "type://Multipart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html"
        }}>{`type://Multipart`}</a>{` decoder to correctly publish chunks one by one when streaming
a large `}<a parentName="li" {...{
          "href": "type://BodyPart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/BodyPart.html"
        }}>{`type://BodyPart`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3774"
        }}>{`#3774`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3783"
        }}>{`#3783`}</a></li>
      <li parentName="ul">{`You can now use `}<inlineCode parentName="li">{`google.protobuf.{Struct, Value, ListValue, Any}`}</inlineCode>{` messages from your `}<inlineCode parentName="li">{`proto`}</inlineCode>{` files when
using gRPC-JSON transcoder. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3986"
        }}>{`#3986`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3992"
        }}>{`#3992`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` now automatically disables gRPC-JSON serialization for Protobuf 2 services
rather than throwing an exception. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4020"
        }}>{`#4020`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4033"
        }}>{`#4033`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
        }}>{`type://FileService`}</a>{` now keeps query parameters when redirecting to a directory. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4049"
        }}>{`#4049`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4050"
        }}>{`#4050`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`InvocationTargetException`}</inlineCode>{` with a Kotlin method whose return type is `}<inlineCode parentName="li">{`Nothing`}</inlineCode>{`
in an annotated service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3961"
        }}>{`#3961`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4005"
        }}>{`#4005`}</a></li>
      <li parentName="ul">{`Actuator now correctly shows `}<inlineCode parentName="li">{`Liveness`}</inlineCode>{` and `}<inlineCode parentName="li">{`Readiness`}</inlineCode>{` of a Kubernetes instance. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3031"
        }}>{`#3031`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3997"
        }}>{`#3997`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CircuitBreaker#canRequest():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html#canRequest()"
        }}>{`type://CircuitBreaker#canRequest()`}</a>{` has been deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://CircuitBreaker#tryRequest():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html#tryRequest()"
        }}>{`type://CircuitBreaker#tryRequest()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4012"
        }}>{`#4012`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Cookie#builder(String,String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Cookie.html#builder(java.lang.String,java.lang.String)"
        }}>{`type://Cookie#builder(String,String)`}</a>{` and `}<a parentName="li" {...{
          "href": "type://Cookie#of(String,String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Cookie.html#of(java.lang.String,java.lang.String)"
        }}>{`type://Cookie#of(String,String)`}</a>{` have been deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://Cookie#secureBuilder(String,String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Cookie.html#secureBuilder(java.lang.String,java.lang.String)"
        }}>{`type://Cookie#secureBuilder(String,String)`}</a>{` and `}<a parentName="li" {...{
          "href": "type://Cookie#ofSecure(String,String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Cookie.html#ofSecure(java.lang.String,java.lang.String)"
        }}>{`type://Cookie#ofSecure(String,String)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3788"
        }}>{`#3788`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3939"
        }}>{`#3939`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcServiceBuilder#setMaxInboundMessageSizeBytes(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#setMaxInboundMessageSizeBytes(int)"
        }}>{`type://GrpcServiceBuilder#setMaxInboundMessageSizeBytes(int)`}</a>{` and `}<a parentName="li" {...{
          "href": "type://GrpcServiceBuilder#setMaxOutboundMessageSizeBytes(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#setMaxOutboundMessageSizeBytes(int)"
        }}>{`type://GrpcServiceBuilder#setMaxOutboundMessageSizeBytes(int)`}</a>{`
have been deprecated in favor of `}<a parentName="li" {...{
          "href": "type://GrpcServiceBuilder#maxRequestMessageLength(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#maxRequestMessageLength(int)"
        }}>{`type://GrpcServiceBuilder#maxRequestMessageLength(int)`}</a>{` and
`}<a parentName="li" {...{
          "href": "type://GrpcServiceBuilder#maxResponseMessageLength(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#maxResponseMessageLength(int)"
        }}>{`type://GrpcServiceBuilder#maxResponseMessageLength(int)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3999"
        }}>{`#3999`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://AbstractBlockingHttpVfs#get(Executor,String,Clock,String,HttpHeaders):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/AbstractBlockingHttpVfs.html#get(java.util.concurrent.Executor,java.lang.String,java.time.Clock,java.lang.String,com.linecorp.armeria.common.HttpHeaders)?full"
        }}>{`type://AbstractBlockingHttpVfs#get(Executor,String,Clock,String,HttpHeaders)?full`}</a>{` has been deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://AbstractBlockingHttpVfs#get(Executor,String,Clock,String,HttpHeaders,MediaTypeResolver):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/AbstractBlockingHttpVfs.html#get(java.util.concurrent.Executor,java.lang.String,java.time.Clock,java.lang.String,com.linecorp.armeria.common.HttpHeaders,com.linecorp.armeria.server.file.MediaTypeResolver)?full"
        }}>{`type://AbstractBlockingHttpVfs#get(Executor,String,Clock,String,HttpHeaders,MediaTypeResolver)?full`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4009"
        }}>{`#4009`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpVfs#get(Executor,String,Clock,String,HttpHeaders):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpVfs.html#get(java.util.concurrent.Executor,java.lang.String,java.time.Clock,java.lang.String,com.linecorp.armeria.common.HttpHeaders)?full"
        }}>{`type://HttpVfs#get(Executor,String,Clock,String,HttpHeaders)?full`}</a>{` has been deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://HttpVfs#get(Executor,String,Clock,String,HttpHeaders,MediaTypeResolver):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpVfs.html#get(java.util.concurrent.Executor,java.lang.String,java.time.Clock,java.lang.String,com.linecorp.armeria.common.HttpHeaders,com.linecorp.armeria.server.file.MediaTypeResolver)?full"
        }}>{`type://HttpVfs#get(Executor,String,Clock,String,HttpHeaders,MediaTypeResolver)?full`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4009"
        }}>{`#4009`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HealthChecker#of(Supplier,Duration):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthChecker.html#of(java.util.function.Supplier,java.time.Duration)?full"
        }}>{`type://HealthChecker#of(Supplier,Duration)?full`}</a>{` and
`}<a parentName="li" {...{
          "href": "type://HealthChecker#of(Supplier,Duration,EventExecutor):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthChecker.html#of(java.util.function.Supplier,java.time.Duration,io.netty.util.concurrent.EventExecutor)?full"
        }}>{`type://HealthChecker#of(Supplier,Duration,EventExecutor)?full`}</a>{` now returns
`}<a parentName="li" {...{
          "href": "type://ListenableHealthChecker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/ListenableHealthChecker.html"
        }}>{`type://ListenableHealthChecker`}</a>{` instead of `}<a parentName="li" {...{
          "href": "type://HealthChecker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthChecker.html"
        }}>{`type://HealthChecker`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4017"
        }}>{`#4017`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServerErrorHandler#renderStatus(ServiceConfig,RequestHeaders,HttpStatus,String,Throwable)"
        }}>{`type://ServerErrorHandler#renderStatus(ServiceConfig,RequestHeaders,HttpStatus,String,Throwable)`}</a>{` now
requires an additional parameter `}<a parentName="li" {...{
          "href": "type://RequestHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeaders.html"
        }}>{`type://RequestHeaders`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4037"
        }}>{`#4037`}</a>
        <ul parentName="li">
          <li parentName="ul">{`This allows a user to generate a different error response depending on the request header value.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.13.3 → 5.13.7`}</li>
      <li parentName="ul">{`Bouncy Castle 1.69 → 1.70`}</li>
      <li parentName="ul">{`Bucket4J 6.3.0 → 7.0.0`}</li>
      <li parentName="ul">{`Caffeine 2.9.2 → 2.9.3`}</li>
      <li parentName="ul">{`Dropwizard 2.0.25 → 2.0.28`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.2.4 → 4.2.7`}</li>
      <li parentName="ul">{`gRPC Java 1.41.1 → 1.43.2`}</li>
      <li parentName="ul">{`gRPC Kotlin 1.1.0 → 1.2.1`}</li>
      <li parentName="ul">{`Jackson 2.13.0 → 2.13.1`}</li>
      <li parentName="ul">{`java-jwt 3.18.2 → 3.18.3`}</li>
      <li parentName="ul">{`SLF4J 1.7.32 → 1.7.34`}</li>
      <li parentName="ul">{`jboss-logging 3.4.2 → 3.4.3`}</li>
      <li parentName="ul">{`Kafka 3.0.0 → 3.1.0`}</li>
      <li parentName="ul">{`Kotlin 1.5.32 → 1.6.10`}</li>
      <li parentName="ul">{`Logback 1.2.7 → 1.2.10`}</li>
      <li parentName="ul">{`Micrometer 1.7.6 → 1.8.2`}</li>
      <li parentName="ul">{`Netty 4.1.70 → 4.1.73`}
        <ul parentName="li">
          <li parentName="ul">{`io_uring 0.0.1 → 0.0.11`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Prometheus 0.12.0 → 0.14.1`}</li>
      <li parentName="ul">{`protobuf-jackson 1.2.0 → 2.0.0`}</li>
      <li parentName="ul">{`protobuf-java 3.17.3 → 3.19.2`}</li>
      <li parentName="ul">{`Reactor 3.4.12 → 3.4.14`}</li>
      <li parentName="ul">{`RESTEasy 4.7.3 → 5.0.2`}</li>
      <li parentName="ul">{`Scala 2.13.7 → 2.13.8, ⓧ → 3.1.1`}</li>
      <li parentName="ul">{`scala-collection-compat 2.5.0 → 2.6.0`}</li>
      <li parentName="ul">{`ScalaPB 0.11.6 → 0.11.8`}</li>
      <li parentName="ul">{`Spring 5.3.13 → 5.3.15`}</li>
      <li parentName="ul">{`Spring Boot 2.5.7 → 2.6.3`}</li>
      <li parentName="ul">{`Tomcat 9.0.55 → 9.0.56`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['AmosDoan', 'AngerM', 'LiYing2010', 'anuraaga', 'arhont375', 'chris-ryan-square', 'di-seo', 'freevie', 'ghkim3221', 'gurudatta-carbon', 'heowc', 'hyangtack', 'icepeppermint', 'ikhoon', 'injae-kim', 'jrhee17', 'jupiny', 'KarboniteKream', 'klurpicolo', 'kojilin', 'ks-yim', 'lan17', 'minwoox', 'nvidyala', 'policeman-kh', 'sleeplesslord', 'ta7uw', 'tobias-', 'trustin', 'wasifaleem', 'woohhan']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      